import React, { useState, useEffect, useCallback, useMemo, useLayoutEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import DataGrid from "../../shared/DataGrid"
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import SendIcon from "@mui/icons-material/Send";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DeleteIcon from "@mui/icons-material/Delete";
import MergeIcon from '@mui/icons-material/Merge';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { Link, useParams } from "react-router-dom";
import { PRIORITIES } from "../../../../../constants/Portal/Dashboard/Studies/Consult/Misc";
import PdfPreview from "../../shared/PdfPreview";
import Confirmation from "../../../../../common/Comfirmation";
import SendDialog from "../../shared/sendPackage"
import MergeDialog from "../../shared/MergeDialog"
import DetailPanelContent from "../../shared/DetailPanelContent"
import dayjs from 'dayjs'
import {
  GetStudy,
  GetStudyCount,
  Subscribe,
  Unsubscribe,
  SendStudy,
  DeleteStudy,
  ClearStudy,
  DuplicateStudy,
  GetToken,
  zipApi
} from "../../../../../api";

let abortSignal, abortCountSignal

const underScoreSort = (a,b)=>{
  const e=a.startsWith("_"),
  f=b.startsWith("_");
  return e&&!f?-1:!e&&f?1:0
}

const currentDate = new Date()
const sevenDaysAgoTimestamp = currentDate.getTime() - (7 * 24 * 60 * 60 * 1000)

const debounceTimers = {}
const debounceTime = 1000
const debounceSubTime = debounceTime*2

const Clinic = ({ user, view, filter, access, socketStatus }) => {
  const [studies, setStudies] = useState([])
  const [merging, setMerging] = useState()
  const [dialogState, setDialogState] = useState({
    open: false,
    studyId: null,
  })
  const [sendDialogState, setSendDialogState] = useState({})
  const [mergeDialogState, setMergeDialogState] = useState({})
  const [apiParams, setApiParams] = useState({sort: 'MainDicomTags.StudyDate', order: 'desc', limit: '', offset: ''})
  const [loading, setLoading] = useState(false)
  const [rowCount, setRowCount] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [search, setSearch] = useState('')
  const [orderedFields, setOrderedFields] = useState()
  const [subs, setSubs] = useState([])

  const subsRef = useRef()
  subsRef.current = subs

  const params = useParams();
  const { org } = params;

  const [deleteConfirmation, setdeleteConfirmation] = useState({
    open: false,
    text: "",
    studyId: undefined,
    api: undefined
  })

  const apiRef = useGridApiRef();
  const [initialState, setInitialState] = React.useState();

  const saveSnapshot = React.useCallback(() => {
    if (apiRef.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState();
      localStorage.setItem('clinicDataGridState', JSON.stringify(currentState));
    }
  }, [apiRef]);

  const initColumns = useCallback(() => {
    if (!orderedFields && apiRef.current.getAllColumns) {
      setOrderedFields(apiRef.current?.getAllColumns().map(e=>e.field))
    }
  }, [apiRef, orderedFields])
  initColumns() // check every render

  useLayoutEffect(() => {
    const stateFromLocalStorage = localStorage?.getItem('clinicDataGridState')
    const state = stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {
      sorting: { sortModel: [{ field: "MainDicomTags.StudyDate", sort: "desc" }] },
    }
    setInitialState(state)
    setOrderedFields(state.columns?.orderedFields?.sort(underScoreSort))

    // handle refresh and navigating away/refreshing
    window.addEventListener('beforeunload', saveSnapshot);

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener('beforeunload', saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot]);

  const deleteConfirm = () => {
    deleteConfirmation.api(deleteConfirmation.studyId).then(() => {
      setStudies((i) => {
        let studies = [...i];
        studies.splice(
          studies.findIndex((x) => x.id === deleteConfirmation.studyId),
          1
        )
        return studies;
      })
      setdeleteConfirmation(
        (i) => (i = { open: false, text: "", studyId: undefined, api: undefined })
      )
    }).catch(e=>e)
  }
  const deleteCancel = () => {
    setdeleteConfirmation(
      (i) => (i = { open: false, text: "", studyId: undefined, api: undefined })
    )
  }
  const deleteClick = (studyId, studyName, event, weekOld) => {
    if (weekOld || event.shiftKey)
      return DeleteStudy(studyId)
    setdeleteConfirmation(
      (i) =>
        (i = {
          open: true,
          text: `Are you sure you want to delete study: ${studyName}?`,
          studyId,
          api: DeleteStudy
        })
    )
  }
  const clearClick = (studyId, studyName) => {
    setdeleteConfirmation(
      (i) =>
        (i = {
          open: true,
          text: `Are you sure you want to clear study: ${studyName}?`,
          studyId,
          api: ClearStudy
        })
    )
  }

  const onSortChange = params => {
    if (rowCount > pageSize)
      setApiParams(x => ({...x, sort: (params.length && params[0].field) || '', order: (params.length && params[0].sort) || ''}))
  }
  const onPageChange = params => {
    setApiParams(x => ({...x, limit: params.pageSize || '', offset: params.page || ''}))
  }

  const sendOnClose = () => setSendDialogState({})
  const sendOnSend = () => setSendDialogState({})
  const sendPackage = useCallback(params => setSendDialogState({...params, open: true, onClose: sendOnClose, onSend: sendOnSend}), [])

  const medDream = StudyInstanceUID => {
    GetToken({data: StudyInstanceUID}).then(result => {
      let { accessToken } = result
      accessToken && window.open(`https://dream.pacs.corridor.vet/?token=${accessToken}`, '_blank')
    }).catch(e=>e)
    return false
  }

  const getStudyCallback = useCallback(() => {
    if (false && mergeDialogState.open); // Don't warn for mergeDialogState, need it for mergeOnComplete
    abortSignal = new AbortController()
    abortCountSignal = new AbortController()
    GetStudy(null, { ...apiParams, view, filter, org, search }, abortSignal.signal).then(result => {
      setStudies(() => result || [])
      setLoading(false)
    }).catch(e => e) // Catch incase of abort
    GetStudyCount({ view, filter, org, search }, abortCountSignal.signal).then(setRowCount).catch(e => e)
  }, [apiParams, view, filter, org, search, mergeDialogState.open])

  const getStudy = useCallback(() => {
    console.log("A study wants to update")
    abortSignal && abortSignal.abort()
    abortCountSignal && abortCountSignal.abort()
    clearTimeout(debounceTimers.refresh)
    debounceTimers.refresh = setTimeout(getStudyCallback, debounceTime)
  }, [getStudyCallback])

  useEffect(() => {
    setLoading(true)
    getStudy()
    return () => {
      if (abortSignal)
        abortSignal.abort()
    }
  }, [view, filter, org, apiParams, search, getStudy, socketStatus]);

  useEffect(() => {
    const type = (['pending', 'complete'].includes(filter) && filter) || 'insert'
    Subscribe({ coll: 'studies', type }, getStudy)
    return () => socketStatus && Unsubscribe({ coll: 'studies', type })
  }, [filter, org, getStudy, socketStatus])

  const getStudyByIdCallback = useCallback(id => {
    GetStudy(id).then(data => {
      if (data.id) {
        setStudies(studies => {
          const copyStudies = [...studies]
          const i = copyStudies.findIndex((x) => x.id === data.id)
          if (i >= 0) {
            if (data.sentBy || data.deleted) copyStudies.splice(i, 1)
            else copyStudies[i] = { ...data }
          }
          return copyStudies
        })
      }
    }).catch(e =>e)
  }, [])

  const getStudyById = useCallback(id => {
    clearTimeout(debounceTimers[id])
    debounceTimers[id] = setTimeout(() => getStudyByIdCallback(id), debounceSubTime)
  }, [getStudyByIdCallback])

  useEffect(() => {
    if (socketStatus)
      setSubs(subs => {
        const newSubs = []
        for (const study of studies) {
          newSubs.push(study.id)
          if (!subs || !subs.includes(study.id))
            Subscribe({ coll: "studies", type: "update", id: study.id }, resp => getStudyById(resp.id))
        }
        if (subs)
          for (const sub of subs)
            if (!newSubs.includes(sub))
              Unsubscribe({ coll: "studies", type: "update", id: sub })
        return newSubs
      })
    else setSubs([])
  }, [studies, getStudyById, socketStatus])

  useEffect(() => {
    return () =>{
      for (const sub of subsRef.current)
        Unsubscribe({ coll: "studies", type: "update", id: sub })
      setSubs([])
    }
  }, [apiParams, view, filter, org, search])

  /* Dynamic Section */
  const sendStudy = useCallback(id => {
    SendStudy(id).then(() => getStudy()).catch(e=>e)
  }, [getStudy])

  const mergeOnClose = () => setMergeDialogState({})
  const mergeOnSend = () => setMergeDialogState({})
  //const mergeOnComplete = () => {}
  const mergeStudies = useCallback(row => setMerging(m => {
    if (m && row.PatientMainDicomTags?.PatientID === m.PatientMainDicomTags?.PatientID) {
      if (row.id !== m.id)
        setMergeDialogState({merge: [{...m}, {...row}], open: true, onClose: mergeOnClose, onSend: mergeOnSend, onSendComplete: getStudy})
      return
    }
    return row
  }), [getStudy])
  /* End Dynamic Section */

  const columnSort = useCallback((a, b) => (orderedFields && orderedFields.indexOf(a.field) - orderedFields.indexOf(b.field)) || 0, [orderedFields])

  const getDetailPanelContent = useCallback(
    ({ row }) => <DetailPanelContent medDream={medDream} setPdfDialogState={setDialogState} org={access.org.id} row={row} />,
    [access.org.id, setDialogState]
  )

  const onDetailPanelExpandedRowIdsChange = useCallback(ids =>
    ids.slice(0, -1).map(e => apiRef.current.toggleDetailPanel(e))
  , [apiRef])

  const columns = useMemo(() => {

    const renderActionsCell = params => {
      const { row } = params;
      const sent = row.sentBy ? true : false;
      const hasPrio = row.org?.priorities && row.org.priorities.length
      const weekOld = new Date(row.createdAt || row.updatedAt) < sevenDaysAgoTimestamp
      const completeRequired =
        row.consult &&
        row.org &&
        ((hasPrio && (row.priority || row.priority === 0)) || !hasPrio) &&
        ((user.settings?.orgSend && (row.associateOrg?.id === user.org.id || row.aetitle === user.org.aetitle)) || !user.settings?.orgSend) &&
        (row.numImages || row.numImages === 0) &&
        row.MainDicomTags.StudyDescription &&
        row.PatientMainDicomTags.ResponsiblePerson &&
        row.PatientMainDicomTags.PatientName &&
        row.PatientMainDicomTags.PatientID &&
        row.PatientMainDicomTags.PatientSex &&
        row.PatientMainDicomTags.PatientBirthDate &&
        row.PatientMainDicomTags.PatientSpeciesDescription &&
        row.PatientMainDicomTags.PatientWeight &&
        (!row.org?.settings?.requireBilling || row.billing?.length) &&
        (!row.org?.settings?.requirePhysician || row.MainDicomTags.ReferringPhysicianName)

      return (
        <>
          <Tooltip title="Download">
            <IconButton
              aria-label="download"
              component={Link}
              to={`${zipApi}studies/${row.id}/zip`}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Viewer">
            <IconButton
              aria-label="view"
              component={Link}
              disabled={!row.Series?.length}
              onClick={() => medDream(row.MainDicomTags.StudyInstanceUID)}
            >
              <BrokenImageIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Rename">
            <IconButton
              component={Link}
              to={`/studies/${row.id}`}
              aria-label="rename"
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Duplicate">
            <IconButton
              component={Link}
              onClick={() => DuplicateStudy(row.id)}
              aria-label="duplicate"
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          {!row.claimBy && !row.duplicate && (
            <Tooltip title="Merge">
              <IconButton
                component={Link}
                aria-label="merge"
                onClick={() => mergeStudies(row)}
              >
                <MergeIcon color={
                  (merging && (
                    (merging.id === row.id && 'warning') ||
                    (merging.PatientMainDicomTags?.PatientID === row.PatientMainDicomTags?.PatientID && 'primary')
                  )) || 'inherit'
                } />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Consult">
            <span>
              <IconButton
                component={Link}
                to={`/studies/${row.id}/consult`}
                aria-label="consult"
                disabled={sent}
              >
                <AssignmentIcon />
              </IconButton>
            </span>
          </Tooltip>
          {!sent && (
            <Tooltip title="Send">
              <span>
                <IconButton
                  aria-label="send"
                  onClick={() => sendStudy(`${row.id}`)}
                  disabled={!completeRequired}
                >
                  <SendIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {row.completeBy && (
            <Tooltip title="View Report">
              <IconButton
                aria-label="view report"
                onClick={() =>
                  setDialogState({ open: true, studyId: `${row.id}` })
                }
              >
                <PictureAsPdfIcon />
              </IconButton>
            </Tooltip>
          )}
          {!row.completeBy && !row.claimBy && ((row.sentBy && (
            <Tooltip title="Clear">
              <span>
                <IconButton
                  aria-label="clear"
                  onClick={() =>
                    clearClick(
                      row.id,
                      `${row.PatientMainDicomTags.PatientName || ""} ${
                        row.MainDicomTags.StudyDescription || ""
                      }`.trim()
                    )
                  }
                >
                  <SettingsBackupRestoreIcon />
                </IconButton>
              </span>
            </Tooltip>
          )) || (
            <Tooltip title={weekOld ? 'Instant Delete' : 'Delete'}>
              <span>
                <IconButton
                  aria-label="delete"
                  disabled={access.org?.id !== '6369d1be630e82c6bb9c8def' && (row.associateOrg?.id === '6369d1be630e82c6bb9c8def' || row.aetitle === 'ccvh')}
                  color={weekOld ? 'info': 'default'}
                  onClick={e =>
                    deleteClick(
                      row.id,
                      `${row.PatientMainDicomTags.PatientName || ""} ${
                        row.MainDicomTags.StudyDescription || ""
                      }`.trim(),
                      e, weekOld
                    )
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          ))}
          <Tooltip title="Email Package">
            <span>
              <IconButton
                aria-label="email package"
                onClick={() => sendPackage({studyId: row.id, disableReport: !(row.completeBy && !row.editing)})}
              >
                <ForwardToInboxIcon />
              </IconButton>
            </span>
          </Tooltip>
        </>
      )
    }
   
    const c = [{
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      minWidth: 9*40+20,
      fontWeight: 600,
      sortable: false,
      resizable: false,
      hideable: false,
      filterable: false,
      disableColumnSelector: true,
      renderCell: renderActionsCell,
    },
    {
      field: "priority",
      headerName: "Priority",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      valueGetter: (value, row, colDef, apiRef) => {
        const pro = row.priority || 0
        return PRIORITIES.find(e=>e.id===pro).name || ''
      },
    },
    {
      field: "MainDicomTags.StudyDate",
      headerName: "Study Date & Time",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      minWidth: 150,
      type: "dateTime",
      valueGetter: (value, row, colDef, apiRef) => new Date(row.MainDicomTags.StudyTimestamp),
      renderCell: params => {
        const outputOptions = {
          year: "2-digit",
          month: "numeric",
          day: "numeric",
          hour: params.row.MainDicomTags.StudyTime ? "numeric" : undefined,
          minute: params.row.MainDicomTags.StudyTime ? "numeric" : undefined,
        }
        const timestamp = new Date(params.value)
        return timestamp.toLocaleString("en-US", outputOptions)
      },
    },
    {
      field: "sentAt",
      headerName: "Request Date",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      minWidth: 150,
      type: "dateTime",
      valueGetter: (value, row, colDef, apiRef) => row.sentAt && dayjs(row.sentAt).toDate(), 
      renderCell: (params) => {
        const outputOptions = {
          year: "2-digit",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          timeZone: params.row.org?.timezone || 'America/Los_Angeles'
        }
        return (params.value && params.value.toLocaleString("en-US", outputOptions)) || ''
      }
    },
    {
      field: "PatientMainDicomTags.PatientID",
      headerName: "Patient ID",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      valueGetter: (value, row, colDef, apiRef) => row.PatientMainDicomTags.PatientID,
    },
    {
      field: "PatientMainDicomTags.PatientName",
      headerName: "Patient Name",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      minWidth: 200,
      valueGetter: (value, row, colDef, apiRef) => row.PatientMainDicomTags.PatientName,
    },
    {
      field: "PatientMainDicomTags.ResponsiblePerson",
      headerName: "Owner Name",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      minWidth: 200,
      valueGetter: (value, row, colDef, apiRef) => row.PatientMainDicomTags.ResponsiblePerson,
    },
    {
      field: "modality",
      headerName: "Modality",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      sortable: false,
      filterable: false,
      valueGetter: (value, row, colDef, apiRef) => row.Series && row.Series.map(e=>e.MainDicomTags && e.MainDicomTags.Modality).filter((v,i,a)=>a.indexOf(v)===i).join(', '),
    },
    {
      field: "MainDicomTags.StudyDescription",
      headerName: "Study Description",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      minWidth: 200,
      valueGetter: (value, row, colDef, apiRef) => row.MainDicomTags.StudyDescription,
    },
    {
      field: "numImages",
      headerName: "Images",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      sortable: false,
      filterable: false,
      valueGetter: (value, row, colDef, apiRef) => {
        let i = 0
        row.Series && row.Series.forEach(e =>
          e.Instances && e.Instances.forEach(f => ++i)
        )
        return row.imageCount = i // Attaching imageCount to the study for merge function
      },
      renderCell: params => params.value + (params.row.media && params.row.media.length ? `+${params.row.media.length}` : '') + (params.row.numImages ? ` of ${params.row.numImages}` : '')
    }]

    return c.sort(columnSort)

  }, [columnSort, sendStudy, sendPackage, merging, mergeStudies, access.org.id, user])

  const handleColumnOrderChange = useCallback(params => {
    setOrderedFields(prevOrderedFields => {
      if (prevOrderedFields) {
        const newOrderedColumns = [...prevOrderedFields];
        const oldIndex = params.oldIndex;
        const targetIndex = params.targetIndex;
        const oldColumn = prevOrderedFields[oldIndex];
        newOrderedColumns.splice(oldIndex, 1);
        newOrderedColumns.splice(targetIndex, 0, oldColumn);
        return newOrderedColumns;
      }
    })
  }, [])

  if (!initialState) {
    return <CircularProgress />;
  }

  return (
    <Paper style={{ height: 700, width: "100%" }}>
      <TextField
        id="search"
        label="Search"
        type="search"
        variant="filled"
        fullWidth
        inputProps={{cx: {ml: 0.25}}}
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
      <DataGrid
        columns={columns}
        rows={studies}
        pageSize={pageSize}
        sx={{ minWidth: 650 }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        loading={loading}
        filterMode="server"
        sortingMode={rowCount > pageSize ? 'server' : 'client'}
        paginationMode="server"
        onSortModelChange={onSortChange}
        onPaginationModelChange={onPageChange}
        disableColumnFilter={true}
        pagination={true}
        disableChildrenFiltering={true}
        disableChildrenSorting={true}
        disableAggregation={true}
        disableRowGrouping={true}
        rowCount={rowCount}
        apiRef={apiRef}
        initialState={initialState}
        onColumnOrderChange={handleColumnOrderChange}
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={getDetailPanelContent}
        onDetailPanelExpandedRowIdsChange={onDetailPanelExpandedRowIdsChange}
        //rowBuffer={100} // Amount of rows allowed to be expanded before deleting old renders
      />
      <PdfPreview
        open={dialogState.open}
        studyId={dialogState.studyId}
        setDialogState={setDialogState}
      />
      <Confirmation
        open={deleteConfirmation.open}
        onCancel={deleteCancel}
        onConfirm={deleteConfirm}
      >
        {deleteConfirmation.text}
      </Confirmation>
      <SendDialog dialogState={sendDialogState} />
      <MergeDialog dialogState={mergeDialogState} />
    </Paper>
  );
};

export default Clinic;
