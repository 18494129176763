import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Autocomplete,
  Container,
  Grid,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Typography,
  TextField,
  Paper,
  MenuItem,
  Checkbox,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from "@mui/icons-material/Send";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { useNavigate, useParams } from "react-router-dom";
import { usePrompt } from "../../../../../../common/usePrompt"
import {
  GetStudy,
  UpdateConsult,
  GetOrg,
  UpdateStudy,
  SendStudy,
} from "../../../../../../api";
import RichText from "../../../../../../common/Editor";
import {
  GENDER_OPTIONS,
  SPECIES_OPTIONS,
  PRIORITIES
} from "../../../../../../constants/Portal/Dashboard/Studies/Consult/Misc";
import FileDrop from "../../../shared/fileDrop";
import moment from "moment";
import dayjs from 'dayjs';
import TextFieldsIcon from '@mui/icons-material/TextFields';

const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
]
const emailTest = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
const orgsDefault = [{id: '62a5265ffac787a63025a95a', name: 'South Sound Veterinary Imaging'}, {id: '63f6af940768bc34a1875cca', name: 'Cardiology-SSVI'}, {id: '65aee8888bfb716cc1ede82b', name: 'SSVI IM'}]
let changeTimeout

const StudyConsult = ({ user, access, edit }) => {

  const params = useParams()
  const [content, setContent] = useState({
    History: { label: "Clinical History" },
    Answers: { label: "Questions to be answered" },
    Previous: { label: "Date of previous imaging/report (if applicable)" },
  });
  const [study, setStudy] = useState(false);
  const [open, setOpen] = useState(false);
  const [newField, setNewField] = useState("");
  const [orgs, setOrgs] = useState([]);
  const [org, setOrg] = useState("");
  const [orgSettings, setOrgSettings] = useState()
  const [associateOrg, setAssociateOrg] = useState();
  const [associateOrgs, setAssociateOrgs] = useState();
  const [requestees, setRequestees] = useState([]);
  const [requestee, setRequestee] = useState('');
  const [billing, setBilling] = useState([]);
  const [billingCodes, setBillingCodes] = useState();
  const [priority, setPriority] = useState('');
  const [priorities, setPriorities] = useState([])
  const [emails, setEmails] = useState()
  const [emailOptions, setEmailOptions] = useState([])
  const [numImages, setNumImages] = useState('');
  const [numImagesUnknown, setNumImagesUnknown] = useState(false);
  const navigate = useNavigate();
  const [changes, setChanges] = useState(true);
  const [media, setMedia] = useState([]);
  const [autoSave, setAutoSave] = useState()

  const completeRequired = useMemo(() => {
    const hasPrio = priorities && priorities.length
    if (associateOrg && user.settings?.orgSend && associateOrg !== user.org.id)
      return false
    return (
      org &&
      ((hasPrio && (priority || priority === 0)) || !hasPrio) &&
      (numImagesUnknown || (numImages || numImages === 0)) &&
      study.MainDicomTags.StudyDescription &&
      study.PatientMainDicomTags.ResponsiblePerson &&
      study.PatientMainDicomTags.PatientName &&
      study.PatientMainDicomTags.PatientID &&
      study.PatientMainDicomTags.PatientSex &&
      study.PatientMainDicomTags.PatientBirthDate &&
      study.PatientMainDicomTags.PatientSpeciesDescription &&
      study.PatientMainDicomTags.PatientWeight &&
      (!orgSettings?.requireBilling || billing?.length) &&
      (!orgSettings?.requirePhysician || study.MainDicomTags.ReferringPhysicianName)
    )
  }, [org, priorities, priority, numImages, numImagesUnknown, study, user, associateOrg, billing, orgSettings])

  const expectedNumImages = useMemo(() => {
    let i = 0
    study.Series && study.Series.forEach(e =>
      e.Instances && e.Instances.forEach(f => ++i)
    )
    if (study.media?.length)
      i += study.media.length
    return i
  }, [study])

  const updateOrg = useCallback(v => {
    setOrg(v)
    setPriority('')
    setBilling([])
    GetOrg(v).then(resp => {
      setOrgSettings(resp.settings)
      setRequestees(i => {
        const users = [...resp.users] || []
        users.length && users.unshift({name: '[First Available]', id: '0'})
        if (requestee) users.find(e=>e.id===requestee) || setRequestee('0')
        return users
      })
      setBillingCodes(resp.billing || [])
      setPriorities(resp.priorities || [])
    }).catch(e=>e)
  }, [requestee])

  const goBack = useCallback(num => {
    if (window.history.state && window.history.state.idx > 0) navigate(num)
    else navigate('/', { replace: true })
  }, [navigate])

  const updateConsult = useCallback(() =>
    UpdateConsult(study.id, {org, report: content, associateOrg, requestee, priority, numImages: numImagesUnknown ? 0 : numImages, media, billing: billing.map(e=>e.id), emails, edit}).then(()=>setChanges(false)).catch(e=>e)
  , [study.id, org, content, associateOrg, requestee, priority, numImagesUnknown, numImages, media, billing, emails, edit])

  useEffect(() => {
    try {
      if (localStorage)
        setEmailOptions(JSON.parse(localStorage.getItem('clinicConsultEmails')) || [])
    } catch (e) {}
  }, [])

  const saveEmailOptions = useCallback(() => {
    try {
      if (localStorage) {
        const emailOptionsCopy = [...emailOptions]
        emails.forEach(e => emailOptionsCopy.find(e2 => e.toLowerCase() === e2.toLowerCase()) || emailOptionsCopy.push(e))
        localStorage.setItem('clinicConsultEmails', JSON.stringify(emailOptionsCopy))
      }
    } catch (e) {}
  }, [emailOptions, emails])

  useEffect(() => {
    if (params.studyId !== study.id)
      GetStudy(params.studyId).then(resp => {
        if ("consult" in resp) setContent((e) => Object.assign(e, resp.consult));
        setStudy(resp);
        if (resp.org)
          updateOrg(resp.org.id)
        else if (orgs.length)
          updateOrg(orgs[0].id)
        setPriority(resp.priority)
        if (resp.numImages === 0)
          setNumImagesUnknown(true)
        else
          setNumImages(resp.numImages || '')
        if (resp.requestee || resp.requestee === 0) setRequestee(resp.requestee.id)
        resp.billing && setBilling(resp.billing)
        resp.media && setMedia(resp.media)
        resp.associateOrg && setAssociateOrg(resp.associateOrg.id)
        setEmails(() => {          
          if (resp.emails?.length) return resp.emails
          if (!resp.associateOrg && resp.aetitle) {
            const access = user.access.find(x => 
              (Array.isArray(x.org.aetitle) && x.org.aetitle.includes(resp.aetitle))
              || x.org.aetitle === resp.aetitle
            )
            if (access?.org?.email)
              return [access.org.email]
          }
          const email = resp.associateOrg?.email  || user.email
          return [email]
        })
      }).catch(e=>e)
      return () => {
        if (changeTimeout) {
          clearTimeout(changeTimeout)
          changeTimeout = undefined 
        }
      }
  }, [params.studyId, study.id, orgs, user, updateOrg])

  useEffect(() => {
    GetOrg(access.org.id).then(resp => {
      const { orgs } = resp
      setOrgs((orgs.length && orgs) || orgsDefault)
    }).catch(e=>e)
  }, [access]);

  useEffect(() => {
    const clinicOrgs = user.access && user.access.filter(e => e.clinic && (e.org.id !== access.org.id))
    clinicOrgs && clinicOrgs.length && setAssociateOrgs([access, ...clinicOrgs])

  }, [user.access, access])

  useEffect(() => {
    if (autoSave) {
      updateConsult().then(() =>
        UpdateStudy(study.id, study).then(() =>
          console.log('Auto saved')
        )
      )
      setAutoSave()
    }
  }, [updateConsult, autoSave, content, study])

  usePrompt("There are unsaved changes, are you sure you want to leave the page?", changes)

  if (!study) return <React.Fragment />;

  const handleSubmit = async (e) => {
    e.preventDefault()
    saveEmailOptions()
    updateConsult()
      .then(() =>
        UpdateStudy(study.id, study).then(() =>
          goBack(-1)
        ).catch(e=>e)
      )
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSaveDialog = () => {
    const fieldShort = newField.replace(/[\W]/g, "");
    if (fieldShort.length && !(fieldShort in content)) {
      setContent((e) => ({ ...e, [fieldShort]: { label: newField } }));
      handleClose();
    }
  };

  const change = (field, value) => {
    setContent((e) => {
      const copyContent = { ...e };
      const copyKey = { ...copyContent[field] }
      copyKey.content = JSON.stringify(value)
      // Check if empty
      if (value && value.length === 1 && value[0].children && value[0].children.length === 1 && !(value[0].children[0].text || value[0].children[0].children))
        value = undefined
      else if (!edit && copyKey.content && !changeTimeout)
        changeTimeout = setTimeout(() => {
          setAutoSave(true)
          changeTimeout = undefined
        }, 15000)
      copyKey.content = JSON.stringify(value)
      copyContent[field] = copyKey;
      return copyContent
    })
  }

  const sendStudy = (id) => {
    saveEmailOptions()
    updateConsult().then(() =>
      UpdateStudy(id, study).then(() =>
        SendStudy(id).then(() => goBack(-1))
      ).catch(e=>e)
    )
  }

  const updateStudy = (e) => {
    setStudy((prev) => {
      let p = { ...prev },
        obj = e.target.name.split(".");
      if (obj.length > 1)
        if (obj[0] in p) p[obj[0]] = { ...p[obj[0]], [obj[1]]: e.target.value };
        else p[obj[0]] = { [obj[1]]: e.target.value };
      else p[obj[0]] = e.target.value;
      return p;
    });
  };

  const updateValues = (value, name) => {
    setStudy((prev) => {
      let p = { ...prev },
        obj = name.split(".");
      if (obj.length > 1)
        if (obj[0] in p) p[obj[0]] = { ...p[obj[0]], [obj[1]]: value };
        else p[obj[0]] = { [obj[1]]: value };
      else p[obj[0]] = value;
      return p;
    });
  }
  
  const changeAssociateOrg = id => {
    setAssociateOrg(id)
    const org = user.access?.find(a => a.org.id === id)
    const { email } = org?.org
    setEmails((email && [email]) || [])
  }

  return (
    <Container>
      <form onSubmit={handleSubmit} noValidate>
        <Grid
          container
          sx={{ mb: 3 }}
          spacing={3}
          justifyContent="left"
          maxWidth="sm"
        >
          {associateOrgs && (
            <FormControl sx={{mb: 1}} fullWidth variant="standard">
              <TextField
                id="associateOrg"
                value={associateOrg || ''}
                label="Referring Organization"
                onChange={e => changeAssociateOrg(e.target.value)}
                variant="standard"
                color="secondary"
                select
                focused
              >
                {associateOrgs.map(e => (
                  <MenuItem key={e.org.id} value={e.org.id}>
                    {e.org.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          )}
          {!edit && orgs && (
            <FormControl sx={{mb: 1}} fullWidth variant="standard">
              <TextField
                id="org"
                value={org || ''}
                label="Reporting Organization"
                onChange={e => updateOrg(e.target.value)}
                variant="standard"
                select
                required
              >
                {orgs.map((e) => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          )}
          {!edit && requestees?.length && !orgSettings?.disableRequestee ? (
            <FormControl sx={{mb: 1}} fullWidth variant="standard">
              <TextField
                id="requestee"
                value={requestee || ''}
                label="Requestee"
                onChange={e => setRequestee(e.target.value)}
                variant="standard"
                select
                required
              >
                {requestees.map(e => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          ) : null}
          {priorities && !!priorities.length && (
            <FormControl sx={{mb: 1}} fullWidth variant="standard">
              <TextField
                id="priority"
                value={priority === null || priority === undefined ? '' : priority}
                label="Priority"
                onChange={e => setPriority(e.target.value)}
                variant="standard"
                select
                required
              >
                {PRIORITIES.filter(e=>!e.id || priorities.includes(e.id)).map((e) => (!e.hidden &&
                  <MenuItem key={e.id} value={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          )}
          {billingCodes && !!billingCodes.length && (
            <FormControl sx={{mb: 1}} fullWidth variant="standard">
              <Autocomplete
                id="billing"
                freeSolo={false}
                multiple
                name="billing"
                options={billingCodes}
                filterSelectedOptions
                getOptionLabel={o=>o.name}
                onChange={(e,v) => setBilling(v)}
                value={billing}
                isOptionEqualToValue={(o,v)=>!orgSettings?.duplicateBillingCodes && o.id===v.id}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" required={orgSettings?.requireBilling} label="Billing Codes" />
                )}
              />
            </FormControl>
          )}
          <FormControl sx={{mb: 1}} fullWidth variant="standard">
            <Autocomplete
              id="emails"
              freeSolo={true}
              multiple
              name="emails"
              filterSelectedOptions
              options={emailOptions}
              value={emails || []}
              clearOnBlur={true}
              onBlur={e => {
                const val = e.target?.value?.trim()
                val && emailTest.test(val) && setEmails(e => [...e, val])
              }}
              onChange={(e,v) => setEmails(v.map(e=>e.trim()).filter(e=>emailTest.test(e)))}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Reporting Emails" />
              )}
            />
          </FormControl>
          <FormControl fullWidth variant="standard">
            <TextField
              id="numImages"
              value={numImages || ''}
              disabled={numImagesUnknown}
              label="Expected Number of Images"
              onChange={e => setNumImages(e.target.value)}
              onWheel={(e) => e.target.blur()}
              InputProps={{ inputProps: { min: 0, type: "number" } }}
              variant="standard"
              required
              inputProps={{ type: 'number', min: 0}}
              helperText={!numImagesUnknown && numImages && parseInt(numImages) !== expectedNumImages && `${expectedNumImages} images currently received`}
              sx={{"& .MuiFormHelperText-root": {color: 'warning.main'}}}
            />
          </FormControl>
          <FormGroup>
            <FormControlLabel control={<Checkbox />} label="Unknown" checked={numImagesUnknown} onChange={e => setNumImagesUnknown(e.target.checked)} />
          </FormGroup>
        </Grid>
        <Paper variant="outlined" sx={{p: 1, mb: 4, width: 600}}>
          <Grid
            sx={{ mb: 3 }}
            container
            spacing={3}
            justifyContent="left"
            maxWidth="sm"
          >
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <TextField
                  name="MainDicomTags.StudyDescription"
                  label="Study Description (ie. Thorax XR, Head CT, Abdominal US)"
                  value={study.MainDicomTags.StudyDescription || ""}
                  onChange={updateStudy}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <TextField
                  name="MainDicomTags.ReferringPhysicianName"
                  label="Referring Physician"
                  value={study.MainDicomTags.ReferringPhysicianName || ""}
                  required={orgSettings?.requirePhysician}
                  onChange={updateStudy}
                  helperText="If entered, will appear on report"
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <TextField
                  name="PatientMainDicomTags.ResponsiblePerson"
                  label="Owner Name"
                  value={study.PatientMainDicomTags.ResponsiblePerson || ""}
                  onChange={updateStudy}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <TextField
                  name="PatientMainDicomTags.PatientName"
                  label="Patient Name"
                  value={study.PatientMainDicomTags.PatientName || ""}
                  onChange={updateStudy}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <TextField
                  name="PatientMainDicomTags.PatientID"
                  label="Patient ID"
                  value={study.PatientMainDicomTags.PatientID || ""}
                  onChange={updateStudy}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <TextField
                  id="gender"
                  value={study.PatientMainDicomTags.PatientSex || ''}
                  name="PatientMainDicomTags.PatientSex"
                  label="Gender"
                  variant="outlined"
                  onChange={updateStudy}
                  select
                  required
                >
                  {GENDER_OPTIONS.map((gender) => (
                    <MenuItem key={gender.key} value={gender.val}>
                      {gender.key}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="PatientMainDicomTags.PatientBirthDate"
                    label="Birthdate/Age *"
                    defaultValue={(study.PatientMainDicomTags.PatientBirthDate && dayjs(study.PatientMainDicomTags.PatientBirthDate)) || undefined}
                    onChange={newValue =>
                      updateValues(
                        (newValue ? moment(newValue.$d).format("YYYYMMDD") : ''),
                        "PatientMainDicomTags.PatientBirthDate"
                      )
                    }
                    textField={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
              <Grid container item sm={12} spacing={1}>
                <Grid item sm={12} />
                <Grid item sm={4}>
                  <TextField
                    value={study.PatientMainDicomTags.PatientBirthDate && moment
                      .duration(
                        moment().diff(
                          study.PatientMainDicomTags.PatientBirthDate
                        )
                      )
                      .years()}
                    helperText="Years"
                  />
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    value={study.PatientMainDicomTags.PatientBirthDate && moment
                      .duration(
                        moment().diff(
                          study.PatientMainDicomTags.PatientBirthDate
                        )
                      )
                      .months()}
                    helperText="Months"
                  />
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    value={study.PatientMainDicomTags.PatientBirthDate && moment
                      .duration(
                        moment().diff(
                          study.PatientMainDicomTags.PatientBirthDate
                        )
                      )
                      .days()}
                    helperText="Days"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <Autocomplete
                  id="species"
                  freeSolo
                  name="PatientMainDicomTags.PatientSpeciesDescription"
                  options={SPECIES_OPTIONS}
                  fullWidth
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  value={study.PatientMainDicomTags.PatientSpeciesDescription}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => {
                      updateValues(
                        value,
                        "PatientMainDicomTags.PatientSpeciesDescription"
                      )
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Species" required />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <TextField
                  name="PatientMainDicomTags.PatientBreedDescription"
                  label="Breed"
                  value={study.PatientMainDicomTags.PatientBreedDescription || ""}
                  onChange={updateStudy}
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <TextField
                  type="number"
                  name="PatientMainDicomTags.PatientWeight"
                  label="Weight (kgs)"
                  value={study.PatientMainDicomTags.PatientWeight || ""}
                  onChange={updateStudy}
                  onWheel={(e) => e.target.blur()}
                  required
                  inputProps={{ type: 'number', min: 0}}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
        <Grid
          sx={{ mb: 3 }}
          container
          spacing={4}
          justifyContent="left"
          maxWidth="md"
        >
          {Object.entries(content).map(([key, val]) => (
            <Grid key={key} item sm={12}>
              <FormControl fullWidth variant="standard">
                <Typography component="h2" variant="h6">
                  {val.label}:
                </Typography>
                <RichText
                  initialValue={val.content ? JSON.parse(val.content) : [...initialValue]}
                  onChange={e => change(key, e)}
                />
              </FormControl>
            </Grid>
          ))}
        </Grid>
        <Grid container sx={{ mb: 3, mt: 3 }} spacing={3} justifyContent="center">
          <FormControl fullWidth variant="standard">
            <Button color="secondary" onClick={handleClickOpen} startIcon={<TextFieldsIcon />}>
              Add Field
            </Button>
          </FormControl>
          <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
            <DialogTitle>Add Field</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Add a rich text field to the report for this study
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="desc"
                label="Field Name"
                type="desc"
                fullWidth
                variant="standard"
                onChange={(e) => setNewField(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSaveDialog}>Save</Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <FileDrop media={media} setMedia={setMedia} />
        <Grid container spacing={3} justifyContent="left">
          <Grid item>
            <Button variant="outlined" color="primary" type="submit" startIcon={<SaveIcon />}>
              Save
            </Button>
          </Grid>
          {!edit && (
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                disabled={!completeRequired}
                onClick={() => sendStudy(study.id)}
                startIcon={<SendIcon />}
                sx={{"&:disabled": {backgroundColor: 'rgba(206, 147, 216, 0.25)'}}}
              >
                Send
              </Button>
            </Grid>
          )}
          </Grid>
      </form>
    </Container>
  );
};

export default StudyConsult;
