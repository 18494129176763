import React, { useState, useCallback } from "react";
import {
  Autocomplete,
  Container,
  Grid,
  FormControl,
  Typography,
  TextField,
  Paper,
  MenuItem,
  Button
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers-pro";
import CompleteIcon from "@mui/icons-material/CheckCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FileDrop from "../../shared/fileDrop";
import { NewStudy as NewStudyAPI } from "../../../../../api";
import { useNavigate } from "react-router-dom";

import {
  GENDER_OPTIONS,
  SPECIES_OPTIONS,
} from "../../../../../constants/Portal/Dashboard/Studies/Consult/Misc";
import styled from "styled-components";
import moment from "moment";
import dayjs from 'dayjs';

export const Wrapper = styled(Paper)`
  padding: 0.5em;
  width: 600px;
  margin-bottom: 2em;
`

const StudyNew = ({ access }) => {
  const now = Date.now()
  const [study, setStudy] = useState({MainDicomTags: {StudyDate: moment(now).format("YYYYMMDD"), StudyTime: moment(now).format("HHmm00.000")}, PatientMainDicomTags: {}});
  const [media, setMedia] = useState([]);
  const navigate = useNavigate();

  const completeRequired =
    study.MainDicomTags.StudyDate &&
    study.MainDicomTags.StudyDescription &&
    study.PatientMainDicomTags.ResponsiblePerson &&
    study.PatientMainDicomTags.PatientName &&
    study.PatientMainDicomTags.PatientID &&
    study.PatientMainDicomTags.PatientSex &&
    study.PatientMainDicomTags.PatientBirthDate &&
    study.PatientMainDicomTags.PatientSpeciesDescription &&
    study.PatientMainDicomTags.PatientWeight


  const goBack = useCallback(num => {
    if (window.history.state && window.history.state.idx > 0) navigate(num)
    else navigate('/', { replace: true })
  }, [navigate])

  const handleSubmit = async (e) => {
    e.preventDefault();

    NewStudyAPI({...study, org: access.org.id, media}).then(() => goBack(-1)).catch(e=>e)
  }

  const consultStudy = async () => {
    NewStudyAPI({...study, org: access.org.id, media}).then(e => {
      navigate(`/studies/${e.id}/consult`, { replace: true })
    }).catch(e=>e)
  }

  const updateStudy = (e) => {
    setStudy((prev) => {
      let p = { ...prev },
        obj = e.target.name.split(".");
      if (obj.length > 1)
        if (obj[0] in p) p[obj[0]] = { ...p[obj[0]], [obj[1]]: e.target.value };
        else p[obj[0]] = { [obj[1]]: e.target.value };
      else p[obj[0]] = e.target.value;
      return p;
    });
  };

  const updateValues = (value, name) => {
    setStudy((prev) => {
      let p = { ...prev },
        obj = name.split(".");
      if (obj.length > 1)
        if (obj[0] in p) p[obj[0]] = { ...p[obj[0]], [obj[1]]: value };
        else p[obj[0]] = { [obj[1]]: value };
      else p[obj[0]] = value;
      return p;
    });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Typography component="h2" variant="h6" gutterBottom>
            New Study:
        </Typography>
        <Wrapper variant="outlined">
          <Grid
            sx={{ mb: 3 }}
            container
            spacing={3}
            justifyContent="left"
            maxWidth="sm"
          >
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    defaultValue={dayjs(new Date())}
                    name="MainDicomTags.StudyDate"
                    label="Study Date/Time"
                    onChange={newValue => {
                      updateValues(
                        (newValue ? moment(newValue.$d).format("YYYYMMDD") : ''),
                        "MainDicomTags.StudyDate"
                      )
                      updateValues(
                        (newValue ? moment(newValue.$d).format("HHmm00.000") : ''),
                        "MainDicomTags.StudyTime"
                      )
                    }}
                    textField={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <TextField
                  name="MainDicomTags.StudyDescription"
                  label="Study Description (ie. Thorax XR, Head CT, Abdominal US)"
                  value={study.MainDicomTags.StudyDescription || ""}
                  onChange={updateStudy}
                  required
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <TextField
                  name="PatientMainDicomTags.ResponsiblePerson"
                  label="Owner Name"
                  value={study.PatientMainDicomTags.ResponsiblePerson || ''}
                  onChange={updateStudy}
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <TextField
                  name="PatientMainDicomTags.PatientName"
                  label="Patient Name"
                  value={study.PatientMainDicomTags.PatientName || ''}
                  onChange={updateStudy}
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <TextField
                  name="PatientMainDicomTags.PatientID"
                  label="Patient ID"
                  value={study.PatientMainDicomTags.PatientID || ''}
                  onChange={updateStudy}
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <TextField
                  id="gender"
                  value={study.PatientMainDicomTags.PatientSex || ''}
                  name="PatientMainDicomTags.PatientSex"
                  label="Gender"
                  variant="outlined"
                  onChange={updateStudy}
                  select
                >
                  {GENDER_OPTIONS.map((gender) => (
                    <MenuItem key={gender.key} value={gender.val}>
                      {gender.key}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="PatientMainDicomTags.PatientBirthDate"
                    label="Birthdate/Age"
                    defaultValue={study.PatientMainDicomTags.PatientBirthDate && dayjs(study.PatientMainDicomTags.PatientBirthDate)}
                    onChange={(newValue) =>
                      updateValues(
                        (newValue ? moment(newValue.$d).format("YYYYMMDD") : ''),
                        "PatientMainDicomTags.PatientBirthDate"
                      )
                    }
                    textField={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
              <Grid container item sm={12} spacing={1}>
                <Grid item sm={12} />
                <Grid item sm={4}>
                  <TextField
                    value={moment
                      .duration(
                        moment().diff(
                          study.PatientMainDicomTags.PatientBirthDate
                        )
                      )
                      .years()}
                    helperText="Years"
                  />
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    value={moment
                      .duration(
                        moment().diff(
                          study.PatientMainDicomTags.PatientBirthDate
                        )
                      )
                      .months()}
                    helperText="Months"
                  />
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    value={moment
                      .duration(
                        moment().diff(
                          study.PatientMainDicomTags.PatientBirthDate
                        )
                      )
                      .days()}
                    helperText="Days"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <Autocomplete
                  id="species"
                  freeSolo
                  name="PatientMainDicomTags.PatientSpeciesDescription"
                  options={SPECIES_OPTIONS}
                  fullWidth
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  value={study.PatientMainDicomTags.PatientSpeciesDescription || ''}
                  getOptionLabel={(option) => option}
                  onChange={(event, value) => {
                    if (value) {
                      updateValues(
                        value,
                        "PatientMainDicomTags.PatientSpeciesDescription"
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Species" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <TextField
                  name="PatientMainDicomTags.PatientBreedDescription"
                  label="Breed"
                  value={study.PatientMainDicomTags.PatientBreedDescription || ''}
                  onChange={updateStudy}
                />
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth variant="standard">
                <TextField
                  type="number"
                  name="PatientMainDicomTags.PatientWeight"
                  label="Weight (kgs)"
                  required
                  value={study.PatientMainDicomTags.PatientWeight || ''}
                  onWheel={(e) => e.target.blur()}
                  onChange={updateStudy}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Wrapper>
        <FileDrop media={media} setMedia={setMedia} />
        <Grid container spacing={3} justifyContent="left">
          <Grid item>
            <Button startIcon={<CompleteIcon />} variant="contained" color="primary" type="submit" disabled={!completeRequired}>
              Create
            </Button>
          </Grid>
          <Grid item>
            <Button startIcon={<AssignmentIcon />} variant="contained" color="secondary" onClick={() => consultStudy()} disabled={!completeRequired}>
              Consult
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default StudyNew