import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Clinic from "./Studies/Clinic";
import Teleconsulation from "./Studies/Teleconsulation";
import StudyEdit from "./Studies/Clinic/Edit";
import StudyConsult from "./Studies/Clinic/Consult";
import StudyRespond from "./Studies/Teleconsulation/Respond";
import StudyAddendum from "./Studies/Teleconsulation/Addendum";
import Import from "./Studies/Import";
import StudyNew from "./Studies/New";

const Dashboard = ({ user, access, socketStatus }) => {

  return (
    <Routes>
      <Route path="/import" element={<Import access={access} />} />
      <Route path="/new" element={<StudyNew access={access} />} />
      <Route path="/new-consult" element={<StudyNew access={access} />} />
      <Route path="/studies/:studyId/consult" element={<StudyConsult user={user} access={access} />} />
      <Route path="/studies/:studyId/consult/edit" element={<StudyConsult user={user} access={access} edit={true} />} />
      <Route path="/studies/:studyId/respond" access={access} element={<StudyRespond />} />
      <Route path="/studies/:studyId/addendum" element={<StudyAddendum />} />
      <Route path="/studies/:studyId/media" element={<StudyAddendum />} />
      <Route path="/studies/:studyId" element={<StudyEdit />} />
      <Route
        path="/:org/clinic"
        element={<Clinic user={user} access={access} socketStatus={socketStatus} view="clinic" />}
      />
      <Route
        path="/:org/clinic/today"
        element={<Clinic user={user} access={access} socketStatus={socketStatus} view="clinic" filter="today" />}
      />
      <Route
        path="/:org/clinic/yesterday"
        element={<Clinic user={user} access={access} socketStatus={socketStatus} view="clinic" filter="yesterday" />}
      />
      <Route
        path="/:org/clinic/week"
        element={<Clinic user={user} access={access} socketStatus={socketStatus} view="clinic" filter="week" />}
      />
      <Route
        path="/:org/clinic/all"
        element={<Clinic user={user} access={access} socketStatus={socketStatus} view="clinic" />}
      />
      <Route
        path="/:org/clinic/attention"
        element={<Clinic user={user} access={access} socketStatus={socketStatus} view="clinic" filter="attention" />}
      />
      <Route
        path="/:org/clinic/pending"
        element={<Clinic user={user} access={access} socketStatus={socketStatus} view="clinic" filter="pending" />}
      />
      <Route
        path="/:org/clinic/complete"
        element={<Clinic user={user} access={access} socketStatus={socketStatus} view="clinic" filter="complete" />}
      />
      <Route
        path="/:org/specialist"
        element={<Teleconsulation user={user} access={access} socketStatus={socketStatus} view="specialist" />}
      />
      <Route
        path="/:org/specialist/pending-unclaimed"
        element={
          <Teleconsulation user={user} access={access} socketStatus={socketStatus} view="specialist" filter="pending-unclaimed" />
        }
      />
      <Route
        path="/:org/specialist/pending-claimed"
        element={
          <Teleconsulation user={user} access={access} socketStatus={socketStatus} view="specialist" filter="pending-claimed" />
        }
      />
      <Route
        path="/:org/specialist/pending-available"
        element={
          <Teleconsulation user={user} access={access} socketStatus={socketStatus} view="specialist" filter="pending-available" />
        }
      />
      <Route
        path="/:org/specialist/pending"
        element={
          <Teleconsulation user={user} access={access} socketStatus={socketStatus} view="specialist" filter="pending" />
        }
      />
      <Route
        path="/:org/specialist/complete"
        element={
          <Teleconsulation user={user} access={access} socketStatus={socketStatus} view="specialist" filter="complete" />
        }
      />
      <Route
        path="/:org/specialist/complete-claimed"
        element={
          <Teleconsulation user={user} access={access} socketStatus={socketStatus} view="specialist" filter="complete-claimed" />
        }
      />
      <Route
        path="/:org/specialist/prelim"
        element={
          <Teleconsulation user={user} access={access} socketStatus={socketStatus} view="specialist" filter="prelim" />
        }
      />
      <Route path="/" element={<Home user={user} />} />
    </Routes>
  );
};

export default Dashboard;
