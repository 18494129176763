import React, { useState, useEffect, useCallback, useMemo, useLayoutEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import TextField from '@mui/material/TextField';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import DataGrid  from "../../shared/DataGrid"
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CompleteIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DownloadIcon from "@mui/icons-material/Download";
import OsiriX from "../../shared/OsiriX"
import RateReviewIcon from "@mui/icons-material/RateReview";
import CircularProgress from "@mui/material/CircularProgress";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SmsIcon from '@mui/icons-material/Sms';
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import ConstructionIcon from '@mui/icons-material/Construction';
import EmailIcon from '@mui/icons-material/Email';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Link, useParams } from "react-router-dom";
import PdfPreview from "../../shared/PdfPreview";
import Confirmation from "../../../../../common/Comfirmation";
import SendDialog from "../../shared/sendPackage"
import DetailPanelContent from "../../shared/DetailPanelContent"
import TransferDialog from "../../shared/transferStudy"
import AssignStudy from "../../shared/AssignStudy"
import { PRIORITIES } from "../../../../../constants/Portal/Dashboard/Studies/Consult/Misc";
import moment from "moment";
import dayjs from 'dayjs';
import {
  GetStudy,
  GetStudyCount,
  ClaimStudy,
  UnClaimStudy,
  Subscribe,
  Unsubscribe,
  CompleteStudy,
  ClearStudy,
  GetToken,
  SendReport,
  zipApi
} from "../../../../../api"

let abortSignal, abortCountSignal

const underScoreSort = (a,b)=>{
  const e=a.startsWith("_"),
  f=b.startsWith("_");
  return e&&!f?-1:!e&&f?1:0
}

const debounceTimers = {}
const debounceTime = 1000
const debounceSubTime = debounceTime

const Teleconsulations = ({ user, view, filter, access, socketStatus }) => {
  const [studies, setStudies] = useState([])
  const [dialogState, setDialogState] = useState({
    open: false,
    studyId: null,
  })
  const [sendDialogState, setSendDialogState] = useState({})
  const [transferDialogState, setTransferDialogState] = useState({})
  const [assignDialogState, setAssignDialogState] = useState({})
  const [apiParams, setApiParams] = useState({sort: 'sentAt', order: 'desc', limit: '', offset: ''})
  const [loading, setLoading] = useState(false)
  const [rowCount, setRowCount] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [search, setSearch] = useState('')
  const [orderedFields, setOrderedFields] = useState()
  const [subs, setSubs] = useState([])

  const subsRef = useRef()
  subsRef.current = subs

  const params = useParams();
  const { org } = params;

  const [deleteConfirmation, setdeleteConfirmation] = useState({
    open: false,
    text: "",
    studyId: undefined,
  });

  const apiRef = useGridApiRef();
  const [initialState, setInitialState] = React.useState();

  const saveSnapshot = React.useCallback(() => {
    if (apiRef.current?.exportState && localStorage) {
      const currentState = apiRef.current.exportState();
      localStorage.setItem('TeleconsultDataGridState', JSON.stringify(currentState));
    }
  }, [apiRef])

  
  const initColumns = useCallback(() => {
    if (!orderedFields && apiRef.current.getAllColumns) {
      setOrderedFields(apiRef.current?.getAllColumns().map(e=>e.field))
    }
  }, [apiRef, orderedFields])
  initColumns() // check every render

  useLayoutEffect(() => {
    const stateFromLocalStorage = localStorage?.getItem('TeleconsultDataGridState')
    const state = stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {
      sorting: { sortModel: [{ field: "sentAt", sort: "desc" }] },
    }
    setInitialState(state)
    setOrderedFields(state.columns?.orderedFields?.sort(underScoreSort))

    // handle refresh and navigating away/refreshing
    window.addEventListener('beforeunload', saveSnapshot);

    return () => {
      // in case of an SPA remove the event-listener
      window.removeEventListener('beforeunload', saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot]);

  const deleteConfirm = () => {
    ClearStudy(deleteConfirmation.studyId).then(() => {
      setStudies((i) => {
        let studies = [...i];
        studies.splice(
          studies.findIndex((x) => x.id === deleteConfirmation.studyId),
          1
        );
        return studies;
      });
      setdeleteConfirmation(
        (i) => (i = { open: false, text: "", studyId: undefined })
      );
    }).catch(e=>e)
  };
  const deleteCancel = () => {
    setdeleteConfirmation(
      (i) => (i = { open: false, text: "", studyId: undefined })
    );
  };
  const clearClick = study => {
    setdeleteConfirmation(
      (i) =>
        (i = {
          open: true,
          text: `Are you sure you want to clear study: 
            ${study.PatientMainDicomTags.PatientName.trim() || ""} 
            ${study.MainDicomTags.StudyDescription.trim() || ""}
            `,
          studyId: study.id,
          error: study.report && 'This study contains a saved report'
        })
    );
  }

  const onSortChange = params => {
    if (rowCount > pageSize)
      setApiParams(x => ({...x, sort: (params.length && params[0].field) || '', order: (params.length && params[0].sort) || ''}))
  }
  const onPageChange = params => {
    setApiParams(x => ({...x, limit: params.pageSize || '', offset: params.page || ''}))
  }

  const sendOnClose = () => setSendDialogState({})
  const sendOnSend = () => setSendDialogState({})
  const sendPackage = useCallback(params => setSendDialogState({...params, open: true, onClose: sendOnClose, onSend: sendOnSend}), [])

  const medDream = StudyInstanceUID => {
    GetToken({data: StudyInstanceUID}).then(result => {
      let { accessToken } = result
      accessToken && window.open(`https://dream.pacs.corridor.vet/?token=${accessToken}`, '_blank')
    }).catch(e=>e)
    return false
  }

  const getStudyCallback = useCallback(() => {
    abortSignal = new AbortController()
    abortCountSignal = new AbortController()
    GetStudy(null, { ...apiParams, view, filter, org, search }, abortSignal.signal).then(result => {
      setStudies(() => result || [])
      setLoading(false)
    }).catch(e => e) // Catch incase of abort
    GetStudyCount({ view, filter, org, search }, abortCountSignal.signal).then(c => setRowCount(c)).catch(e => e)
  }, [apiParams, view, filter, org, search])

  const getStudy =  useCallback(() => {
    abortSignal && abortSignal.abort()
    abortCountSignal && abortCountSignal.abort()
    clearTimeout(debounceTimers.refresh)
    debounceTimers.refresh = setTimeout(getStudyCallback, debounceTime)
  }, [getStudyCallback])

  useEffect(() => {
    setLoading(true)
    getStudy()
    return () => {
      abortSignal && abortSignal.abort()
      abortCountSignal && abortCountSignal.abort()
    }
  }, [apiParams, view, filter, org, search, getStudy, socketStatus])

  useEffect(() => {
    const type = (filter && filter.includes('complete') && 'complete') || 'pending'
    Subscribe({ coll: 'studies', type, id: org }, getStudy)
    return () => socketStatus && Unsubscribe({ coll: 'studies', type, id: org })
  }, [filter, org, getStudy, socketStatus])

  const getStudyByIdCallback = useCallback(id => {
    GetStudy(id).then(data => {
      if (data.id) {
        setStudies(studies => {
          const copyStudies = [...studies]
          const i = copyStudies.findIndex((x) => x.id === data.id)
          if (i >= 0) {
            if (!data.sentBy || data.deleted) copyStudies.splice(i, 1)
            else copyStudies[i] = { ...data }
          }
          return copyStudies
        })
      }
    }).catch(e =>e)
  }, [])

  const getStudyById = useCallback(id => {
    clearTimeout(debounceTimers[id])
    debounceTimers[id] = setTimeout(() => getStudyByIdCallback(id), debounceSubTime)
  }, [getStudyByIdCallback])

  useEffect(() => {
    if (socketStatus)
      setSubs(subs => {
        const newSubs = []
        for (const study of studies) {
          newSubs.push(study.id)
          if (!subs || !subs.includes(study.id))
            Subscribe({ coll: "studies", type: "update", id: study.id }, resp => getStudyById(resp.id))
        }
        if (subs)
          for (const sub of subs)
            if (!newSubs.includes(sub))
              Unsubscribe({ coll: "studies", type: "update", id: sub })
        return newSubs
      })
    else setSubs([])

  }, [studies, getStudyById, socketStatus])

  useEffect(() => {
    return () =>{
      for (const sub of subsRef.current)
        Unsubscribe({ coll: "studies", type: "update", id: sub })
      setSubs([])
    }
  }, [apiParams, view, filter, org, search])

  /* Dynamic Section */
  const respondStudy = (e, completed) => {
    if (completed)
      window.confirm('This study has already been marked complete. Are you sure?') || e.preventDefault()
  }
  const completeStudy = useCallback(id => CompleteStudy(id).then(() => getStudy()).catch(e=>e), [getStudy])

  // Transfer dialog
  const transferOnClose = () => setTransferDialogState({})
  const transferOnSend = useCallback((state, orgId) => {
    if (state.studyId && orgId !== org) {
      setStudies(s => {
          const copyStudies = [...s]
          const i = copyStudies.findIndex(x => x.id === state.studyId)
          copyStudies.splice(i, 1)
          return copyStudies
      })
    }
    setTransferDialogState({})

  }, [org])
  const transferStudy = useCallback(params => setTransferDialogState({...params, open: true, onClose: transferOnClose, onSend: transferOnSend}), [transferOnSend])

  // Assign dialog
  const assignOnClose = () => setAssignDialogState({})
  const assignOnSend = () => setAssignDialogState({})
  const assignStudy = useCallback(params => setAssignDialogState({...params, open: true, onClose: assignOnClose, onSend: assignOnSend}), [])


  const sendReport = studyId => SendReport(studyId).then(alert('Report sent')).catch(e=>e)

  const claimStudy = studyId =>
    ClaimStudy(studyId).catch(e=>e)

  const hasTransfer = useMemo(() => user.access.filter(e=>e.consult || e.transfer).length > 1, [user.access])

  /* End Dynamic Section */

  const columnSort = useCallback((a, b) => (orderedFields && orderedFields.indexOf(a.field) - orderedFields.indexOf(b.field)) || 0, [orderedFields])

  const getDetailPanelContent = useCallback(({ row }) => {
      return <DetailPanelContent medDream={medDream} setPdfDialogState={setDialogState} org={access.org.id} row={row} />
  }, [access.org.id, setDialogState])

  const onDetailPanelExpandedRowIdsChange = useCallback(ids =>
    ids.slice(0, -1).map(e => apiRef.current.toggleDetailPanel(e))
  , [apiRef])

  const columns = useMemo(() => {

    const renderActionsCell = params => {
      const { row } = params;
      const claimed = "claimBy" in row && row.claimBy;
      const completed = row.completeBy ? true : false;
      const claimUser = claimed && (user.id === row.claimBy.id)
      const addendumUser = (row.addendumBy?.id === user.id)
      const requestedUser = (row.requestee?.id === user.id)

      return (
        <>
          <Tooltip title="Download">
            <IconButton
              aria-label="download"
              component={Link}
              to={`${zipApi}studies/${row.id}/zip`}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="OsiriX">
            <IconButton
              aria-label="osirix"
              component={Link}
              to={`osirix://?methodName=downloadURL&URL='https://api.pacs.corridor.vet/studies/${row.id}/zip'`}
            >
              <OsiriX />
            </IconButton>
          </Tooltip>
          <Tooltip title="Viewer">
            <IconButton
              aria-label="view"
              component={Link}
              disabled={!row.Series?.length}
              onClick={() => medDream(row.MainDicomTags.StudyInstanceUID)}
            >
              <BrokenImageIcon />
            </IconButton>
          </Tooltip>
          {!row.requestee && !claimed && access?.admin && !!access?.org?.users?.length && (
            <Tooltip title="Assign Study">
              <IconButton
                aria-label="assign study"
                color="info"
                onClick={() => assignStudy({studyId: row.id})}
              >
                <PersonAddIcon />
              </IconButton>
            </Tooltip>
          )}
          {!completed && // requestedUser
            ((claimed && (claimUser || access?.admin)) || (!requestedUser && row.requestee && access?.admin) ? (
              <Tooltip title="Unclaim study">
                <span>
                  <IconButton
                    aria-label="unclaim"
                    color={claimUser ?  'error' : 'warning'}
                    onClick={() => UnClaimStudy(row.id).catch(e=>e)}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Claim study">
                <span>
                  <IconButton
                    disabled={(claimed && (!claimUser || !access?.admin)) || (row.requestee && (!requestedUser))}
                    aria-label="claim"
                    color={row.requestee ? 'info' : 'default'}
                    sx={row.requestee && {"&:disabled": {color: 'rgba(41, 182, 246, 0.25)'}}}
                    onClick={() => claimStudy(row.id)}
                  >
                    <NoteAddIcon />
                  </IconButton>
                </span>
              </Tooltip>
            ))
          }
          <Tooltip title="Respond">
            <span>
              <IconButton
                disabled={!claimUser}
                component={Link}
                to={`/studies/${row.id}/respond`}
                aria-label="respond"
                onClick={(e) => respondStudy(e, completed)}
              >
                <RateReviewIcon />
              </IconButton>
            </span>
          </Tooltip>
          {(completed || access.org?.settings?.viewIncompleteReports) && (
            <Tooltip title="View Report">
              <IconButton
                aria-label="view report"
                onClick={() =>
                  setDialogState({ open: true, studyId: `${row.id}` })
                }
              >
                <PictureAsPdfIcon />
              </IconButton>
            </Tooltip>
          )}
          {!completed && (
            <Tooltip title="Clear">
              <span>
                <IconButton
                  aria-label="clear"
                  onClick={() => clearClick(row)}
                >
                  <SettingsBackupRestoreIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {completed && (
            <Tooltip title="Addendum">
              <span>
                <IconButton
                  aria-label="addendum"
                  component={Link}
                  to={`/studies/${row.id}/addendum`}
                >
                  <SmsIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {row.report && row.editing && (claimUser || addendumUser) && (
            <Tooltip title="Complete">
              <span>
                <IconButton
                  aria-label="complete"
                  disabled={row.org?.billing?.length && !row.billing?.length}
                  onClick={() => completeStudy(`${row.id}`)}
                >
                  <CompleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {completed && !row.editing && (
            <Tooltip title="Email Report">
              <span>
                <IconButton
                  aria-label="email report"
                  onClick={() => sendReport(row.id)}
                >
                  <EmailIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <Tooltip title="Email Package">
            <span>
              <IconButton
                aria-label="email package"
                onClick={() => sendPackage({studyId: row.id, disableReport: !(completed && !row.editing)})}
              >
                <ForwardToInboxIcon />
              </IconButton>
            </span>
          </Tooltip>
          {hasTransfer && !claimed && !row.requestee && (
            <Tooltip title="Transfer Study">
              <span>
                <IconButton
                  aria-label="transfer study"
                  onClick={() => transferStudy({studyId: row.id, orgId: row.org && row.org.id})}
                >
                  <SwapHorizIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {access?.admin && (
            <Tooltip title="Admin Study Edit">
              <span>
                <IconButton
                  aria-label="edit study"
                  component={Link}
                  color="warning"
                  to={`/studies/${row.id}/consult/edit`}
                >
                  <ConstructionIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </>
      );
    }

    const c = [{
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      //minWidth: 275,
      minWidth: 11*40+20, // For transfer demo
      fontWeight: 600,
      sortable: false,
      hideable: false,
      filterable: false,
      disableColumnSelector: true,
      renderCell: renderActionsCell,
    },
    {
      field: "priority",
      headerName: "Priority",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      valueGetter: (value, row, colDef, apiRef) => {
        const pro = row.priority || 0
        return PRIORITIES.find(e=>e.id===pro).name || ''
      },
    },
    {
      field: "sentAt",
      headerName: "Request Date",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      minWidth: 150,
      type: "dateTime",
      valueGetter: (value, row, colDef, apiRef) => row.sentAt && dayjs(row.sentAt).toDate(), 
      renderCell: (params) => {
        const outputOptions = {
          year: "2-digit",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          timeZone: params.row.org?.timezone || 'America/Los_Angeles'
        }
        return (params.value && params.value.toLocaleString("en-US", outputOptions)) || ''
      }
    },
    {
      field: "sentBy.org.name",
      headerName: "Requested By",
      headerAlign: "center",
      align: "center",
      minWidth: 250,
      fontWeight: 600,
      valueGetter: (value, row, colDef, apiRef) => row.associateOrg?.name || row.sentBy?.org?.name || '',
    },
    {
      field: "userData.PatientMainDicomTags.PatientID",
      headerName: "Patient ID",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      valueGetter: (value, row, colDef, apiRef) => row.PatientMainDicomTags.PatientID,
    },
    {
      field: "userData.PatientMainDicomTags.PatientName",
      headerName: "Patient Name",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      minWidth: 200,
      valueGetter: (value, row, colDef, apiRef) => row.PatientMainDicomTags.PatientName,
    },
    {
      field: "userData.PatientMainDicomTags.ResponsiblePerson",
      headerName: "Owner Name",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      minWidth: 200,
      valueGetter: (value, row, colDef, apiRef) => row.PatientMainDicomTags.ResponsiblePerson,
    },
    {
      field: "userData.PatientMainDicomTags.PatientSpeciesDescription",
      headerName: "Species",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      valueGetter: (value, row, colDef, apiRef) => row.PatientMainDicomTags.PatientSpeciesDescription,
    },
    {
      field: "userData.PatientMainDicomTags.PatientBirthDate",
      headerName: "Age",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      type: "dateTime",
      valueGetter: (value, row, colDef, apiRef) => row.PatientMainDicomTags.PatientBirthDate && dayjs(row.PatientMainDicomTags.PatientBirthDate).toDate(),
      renderCell: (params) => (params.value && moment.duration(moment().diff(params.value)).humanize()) || ''
    },
    {
      field: "numImages",
      headerName: "Images",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      sortable: false,
      filterable: false,
      valueGetter: (value, row, colDef, apiRef) => {
        let i = 0
        row.Series && row.Series.forEach(e =>
          e.Instances && e.Instances.forEach(f => ++i)
        )
        return i
      },
      renderCell: params => params.value + (params.row.media && params.row.media.length ? `+${params.row.media.length}` : '') + (params.row.numImages ? ` of ${params.row.numImages}` : '')
    },
    {
      field: "modality",
      headerName: "Modality",
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      fontWeight: 600,
      valueGetter: (value, row, colDef, apiRef) => row.Series && row.Series.map(e=>e.MainDicomTags && e.MainDicomTags.Modality).filter((v,i,a)=>a.indexOf(v)===i).join(', '),
    },
    {
      field: "userData.MainDicomTags.StudyDescription",
      headerName: "Study Description",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      minWidth: 200,
      valueGetter: (value, row, colDef, apiRef) => row.MainDicomTags.StudyDescription,
    },
    {
      field: "claimBy.name",
      headerName: "Claimer",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      minWidth: 250,
      valueGetter: (value, row, colDef, apiRef) => {
        const claimerEmail = row.claimBy?.name || (row.requestee && `[${row.requestee.name}]`) || '';
        return claimerEmail;
      },
    },
    {
      field: "waitTime",
      headerName: "Wait Time",
      headerAlign: "center",
      align: "center",
      fontWeight: 600,
      type: "dateTime",
      filterable: false,
      sortable: false,
      valueGetter: (value, row, colDef, apiRef) => row.sentAt && dayjs(row.sentAt).toDate(), 
      renderCell: (params) => { 
        if (!params.value)
          return ''
        const startime = (params.row.completeAt && moment(params.row.completeAt.last || params.row.completeAt.first)) || moment()
        return moment.duration(startime.diff(params.value)).humanize()
      }
    }]
    return c.sort(columnSort)

  }, [columnSort, sendPackage, completeStudy, transferStudy, assignStudy, user.id, access, hasTransfer])

  const handleColumnOrderChange = useCallback(params => {
    setOrderedFields(prevOrderedFields => {
      if (prevOrderedFields) {
        const newOrderedColumns = [...prevOrderedFields]
        const oldIndex = params.oldIndex
        const targetIndex = params.targetIndex
        const oldColumn = prevOrderedFields[oldIndex]
        newOrderedColumns.splice(oldIndex, 1)
        newOrderedColumns.splice(targetIndex, 0, oldColumn)
        return newOrderedColumns
      }
    })
  }, [])

  if (!initialState) {
    return <CircularProgress />;
  }

  return (
    <Paper style={{ height: 700, width: "100%" }}>
      <TextField
        id="search"
        label="Search"
        type="search"
        variant="filled"
        fullWidth
        inputProps={{cx: {ml: 0.25}}}
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
      <DataGrid
        columns={columns}
        rows={studies}
        pageSize={pageSize}
        sx={{ minWidth: 650 }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        loading={loading}
        filterMode="server"
        sortingMode={rowCount > pageSize ? 'server' : 'client'}
        paginationMode="server"
        onSortModelChange={onSortChange}
        onPaginationModelChange={onPageChange}
        disableColumnFilter={true}
        pagination={true}
        disableChildrenFiltering={true}
        disableChildrenSorting={true}
        disableAggregation={true}
        disableRowGrouping={true}
        rowCount={rowCount}
        apiRef={apiRef}
        initialState={initialState}
        onColumnOrderChange={handleColumnOrderChange}
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={getDetailPanelContent}
        onDetailPanelExpandedRowIdsChange={onDetailPanelExpandedRowIdsChange}
        //rowBuffer={100} // Amount of rows allowed to be expanded before deleting old renders
      />
      <PdfPreview
        open={dialogState.open}
        studyId={dialogState.studyId}
        setDialogState={setDialogState}
      />
      <Confirmation
        open={deleteConfirmation.open}
        onCancel={deleteCancel}
        onConfirm={deleteConfirm}
        error={deleteConfirmation.error}
      >
        {deleteConfirmation.text}
      </Confirmation>
      <SendDialog dialogState={sendDialogState} />
      <TransferDialog dialogState={transferDialogState} user={user} />
      <AssignStudy dialogState={assignDialogState} access={access} />
    </Paper>
  )
}

export default Teleconsulations;
